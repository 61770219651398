import { createAction, props } from '@ngrx/store';

import { Channel } from '../../models';

export const loadRequest = createAction('[Channels] Load Request');

export const loadSuccess = createAction(
  '[Channels] Load Success',
  props<{ items: Channel[] }>(),
);

export const loadFailure = createAction(
  '[Channels] Load Failure',
  props<{ error: any }>(),
);

export const deletePropertyChannelRequest = createAction(
  '[Channels] Delete Property Channel Request',
  props<{ channelId: number; propertyIds: number[] }>(),
);

export const deletePropertyChannelSuccess = createAction(
  '[Channels] Delete Property Channel Success',
);

export const deletePropertyChannelFailure = createAction(
  '[Channels] Delete Property Channel Failure',
  props<{ error: any }>(),
);

export const createFakePropertyChannelsRequest = createAction(
  '[Channels] Create Fake Property Channels Request',
  props<{ channelsIds: number[]; propertyId: number; onSuccess?: () => any }>(),
);

export const createFakePropertyChannelsSuccess = createAction(
  '[Channels] Create Fake Property Channels Success',
  props<{ channelsIds: number[]; propertyId: number }>(),
);

export const createFakePropertyChannelsFailure = createAction(
  '[Channels] Create Fake Property Channels Failure',
  props<{ error: any }>(),
);

export const deleteIdMultiPropertyChannelRequest = createAction(
  '[Channels] Delete Id Multi Property Channel Request',
  props<{ channelId: number; propertyId: number; propertyChannelMultiId }>(),
);

export const deleteIdMultiPropertyChannelSuccess = createAction(
  '[Channels] Delete Id Multi Property Channel Success',
  props<{ channelId: number; propertyId: number; propertyChannelMultiId }>(),
);

export const deleteIdMultiPropertyChannelFailure = createAction(
  '[Channels] Delete Id Multi Property Channel Failure',
  props<{ error: any }>(),
);

export const setMasterPropertyChannelRequest = createAction(
  '[Channels] Set Master Property Channel Request',
  props<{ channelId: number; propertyId: number }>(),
);

export const setMasterPropertyChannelSuccess = createAction(
  '[Channels] Set Master Property Channel Success',
  props<{ channelId: number; propertyId: number }>(),
);

export const setMasterPropertyChannelFailure = createAction(
  '[Channels] Set Master Property Channel Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Channels] Reset State');
