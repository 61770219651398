import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ChannelsStoreEffects } from './effects';
import { channelsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('channels', channelsReducer),
    EffectsModule.forFeature([ChannelsStoreEffects]),
  ],
})
export class ChannelsStoreModule {}
