import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ChannelsService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class ChannelsStoreEffects {
  constructor(
    private dataService: ChannelsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(() =>
        this.dataService.load().pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadSuccess({
              items: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  setMasterPropertyChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setMasterPropertyChannelRequest),
      switchMap((payload) =>
        this.dataService
          .setMasterPropertyChannel(payload.propertyId, payload.channelId)
          .pipe(
            map((response: IResponseSuccess) => {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'property_channel_is_parent_master_success',
                ),
                type: 'success',
              });
              return fromActions.setMasterPropertyChannelSuccess(payload);
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.setMasterPropertyChannelFailure(error));
            }),
          ),
      ),
    ),
  );

  deletePropertyChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deletePropertyChannelRequest),
      switchMap((payload) =>
        this.dataService
          .deletePropertyChannel(payload.propertyIds, payload.channelId)
          .pipe(
            map(() => {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.delete_success',
                  {
                    param: this.translate.instant('channel'),
                  },
                ),
                type: 'success',
              });
              return fromActions.deletePropertyChannelSuccess();
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.deletePropertyChannelFailure(error));
            }),
          ),
      ),
    ),
  );

  createFakePropertyChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createFakePropertyChannelsRequest),
      switchMap((payload) =>
        this.dataService
          .createFakePropertyChannels(payload.propertyId, payload.channelsIds)
          .pipe(
            map((response: IResponseSuccess) => {
              if (payload.onSuccess) {
                payload.onSuccess();
              }
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.create_success',
                  {
                    param: this.translate.instant('channels'),
                  },
                ),
                type: 'success',
              });
              return fromActions.createFakePropertyChannelsSuccess(payload);
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.createFakePropertyChannelsFailure(error));
            }),
          ),
      ),
    ),
  );
  deleteIdMultiPropertyChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteIdMultiPropertyChannelRequest),
      switchMap((payload) =>
        this.dataService
          .deleteIdMultiPropertyChannel(
            payload.propertyId,
            payload.channelId,
            payload.propertyChannelMultiId,
          )
          .pipe(
            map((response: IResponseSuccess) => {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.delete_success',
                  {
                    param: this.translate.instant('channel'),
                  },
                ),
                type: 'success',
              });
              return fromActions.deleteIdMultiPropertyChannelSuccess(payload);
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.deleteIdMultiPropertyChannelFailure(error));
            }),
          ),
      ),
    ),
  );
}
